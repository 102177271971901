<template>
  <v-card class="cross-icon-parent-div pa-4" min-height="500">
    <div class="cross-icon-div cursor-pointer">
      <img
        @click="$emit('closeModal', false)"
        src="../../../assets/icons/card-close-icon.svg"
        class="cross-icon"
      />
    </div>
    <div v-if="loader" class="loader">
      <v-progress-circular
        indeterminate
        color="primary"
        size="50"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-card-title>
        <div class="mx-3">
          <strong> Student Face</strong>
          <v-img
            class="searched-img mt-1"
            :src="this.face_frame_id"
            width="100"
            height="100"
          ></v-img>
        </div>
      </v-card-title>
      <div class="mx-3 mb-3" style="height: 30px">
        <strong>Impersonators</strong>
      </div>
      <v-row class="preview-div">
        <v-col cols="3" v-for="img in recordImages" :key="img._id">
          <div class="searched-img-results">
            <v-img
              @click="openImageModal(img, 1)"
              class="searched-img"
              id="image-preview"
              :src="img.frame_id"
              :lazy-src="img.frame_id"
            >
            </v-img>
          </div>
        </v-col>
      </v-row>
      <v-card-actions></v-card-actions>
    </div>
    <v-dialog
      v-model="imageModal"
      max-width="700px"
      max-height="60vh"
      overlay-opacity="0.8"
    >
      <v-carousel hide-delimiters reverse :show-arrows="imageArray.length > 1">
        <v-carousel-item
          v-for="(item, i) in imageArray"
          :key="i"
          :src="item"
          active-class="image-style"
          class="modal-img"
        ></v-carousel-item>
      </v-carousel>
    </v-dialog>
  </v-card>
</template>

<script>
import ApiService from "@/services/Api";
import { showErrorMessage } from "../../../utils/showError";
export default {
  name: "StudentDetail",
  props: {
    reference: String,
    face_frame: String,
    student_details: Boolean,
  },
  data() {
    return {
      recordImages: [],
      loader: false,
      face_frame_id: "",
      imageModal: false,
      imageArray: [],
    };
  },
  watch: {
    student_details(val) {
      if (val == true) {
        this.getDataFromAPI();
      }
    },
  },
  mounted() {
    if (this.reference) {
      this.getDataFromAPI();
    }
  },
  methods: {
    /**
     * Retrieves data from the API regarding student engagement.
     * Sets loader to true while fetching data and updates recordImages and face_frame_id upon successful response.
     * @param {string} reference - The reference ID of the student.
     */
    getDataFromAPI() {
      this.loader = true;
      let data = {
        reference_id: this.reference,
        is_intruder: true,
      };
      ApiService.PostRequest("merchant/student-engagement-data", data)
        .then((res) => {
          this.recordImages = res.data.result.student_detail;
          this.face_frame_id = res.data.result.logged_frame_url;
          this.loader = false;
        })
        .catch((error) => {
          showErrorMessage(error);
          this.loader = false;
        });
    },

    /**
     * Opens the image modal and sets the imageArray with image URLs based on the provided item.
     * If an index is provided and equals 1, swaps the order of the images in the imageArray.
     * @param {Object} item - The item containing image URLs.
     * @param {number} index - The index indicating which image to display first.
     */
    openImageModal(item, index) {
      this.imageArray = [];
      let img1, img2;
      if (item && item.quick_liveness_photo_id) {
        img1 = `${item.quick_liveness_id_url}`;
        this.imageArray.push(img1);
      }
      if (item && item.photo_id) {
        img2 = `${item.photo_id_url}`;
        this.imageArray.push(img2);
      }
      if (index === 1) {
        [this.imageArray[0], this.imageArray[1]] = [
          this.imageArray[1],
          this.imageArray[0],
        ];
      }
      // Set the dynamic image source for the modal
      this.imageModal = true;
    },
  },
};
</script>
<style scoped>
.cross-icon-parent-div {
  position: relative;
}
.cross-icon-div {
  position: absolute;
  top: -10px;
  right: -7px;
  z-index: 2;
  overflow: visible;
}

.searched-img-results {
  padding: 10px;
  border-radius: 0.625rem;
  background: #fff;
  box-shadow: 0px 1.4px 13px 0px rgba(0, 0, 0, 0.03),
    0px 7px 80px 0px rgba(0, 0, 0, 0.05) !important;
}
.searched-img {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 0.625rem;
}
.preview-div {
  padding: 15px;
  max-height: 500px;
  overflow: scroll;
}
.loader {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-img .v-image__image--cover {
  background-size: contain;
}
.modal-img .v-carousel__item {
  background: #e9e9e9;
}
</style>
