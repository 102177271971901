<template>
  <v-dialog v-model="VideoDialog" max-width="1100">
    <div v-if="loading" class="loader-overlay">
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      ></v-progress-circular>
      <p class="processing-message">Processing...</p>
    </div>
    <v-card v-if="videosLoaded" class="cross-icon-parent-div">
      <div class="cross-icon-div cursor-pointer">
        <img
          @click="closeDialog"
          src="../../assets/icons/card-close-icon.svg"
          class="cross-icon"
        />
      </div>
      <div class="d-flex pa-2 ml-2">
        <div class="mr-2">
          <span class="id_txt">Reference Id:</span>
          <span class="sub-txt ml-1">{{ reference ? reference : "--" }}</span>
        </div>
        <div class="ml-4">
          <span class="id_txt">Session Id:</span>
          <span class="sub-txt ml-1">{{ session ? session : "--" }}</span>
        </div>
      </div>
      <v-card-text class="py-4">
        <v-row>
          <v-col cols="4" md="4">
            <div class="video-container-screen">
              <video
                @loadedmetadata="handleVideoMetadataLoaded"
                @ended="handleVideoEnded"
                @timeupdate="updateTime"
                ref="face_video"
                class="rounded-screen-video"
                height="250"
                width="250"
                :autoplay="true"
                :controls="false"
                :src="`https://ues.facia.ai/video/user/${session}/${reference}`"
              />
            </div>
          </v-col>
          <v-col cols="8" md="4">
            <div class="video-container">
              <video
                @loadedmetadata="handleVideoMetadataLoaded"
                ref="screen_video"
                class="rounded-video"
                height="500px"
                width="700px"
                :autoplay="true"
                :controls="false"
                :src="`https://ues.facia.ai/video/screenshot/${session}/${reference}`"
              />
            </div>
          </v-col>
        </v-row>
        <v-row class="col-12 text-center d-block">
          <v-progress-linear
            v-if="totalTime > 0"
            class="mb-2"
            :value="progressPercentage"
          ></v-progress-linear>
          <!-- Control buttons -->
          <div class="buttons">
            <div class="mt-2">
              <span
                class="mr-2 cursor-pointer"
                @click="showStudentDetails('open')"
              >
                <strong>Impersonator:</strong>
                <span :class="{ 'red-color': intruder > 0 }">
                  {{ intruder > 0 ? "Yes" : "--" }}</span
                >
              </span>
              <span class="mr-2">
                <strong>Engagement:</strong>
                {{ engagement ? engagement + "%" : "--" }}</span
              >
            </div>
            <div>
              <!-- Play/pause button -->
              <v-btn @click="togglePlayback">
                {{ isPlaying ? "Pause" : "Play" }}
                <v-icon size="20">
                  {{ isPlaying ? "mdi-pause" : "mdi-play" }}
                </v-icon>
              </v-btn>

              <!-- Speed control buttons -->
              <v-btn @click="changeSpeed(0.5)">
                <v-icon size="20"> mdi-rewind </v-icon>
              </v-btn>
              <v-btn @click="changeSpeed(1)">{{ speed }}x</v-btn>
              <v-btn @click="changeSpeed(2)">
                <v-icon size="20"> mdi-fast-forward </v-icon>
              </v-btn>
            </div>
            <div clas="d-flex align-start flex-column">
              <!-- Time display -->
              <div>
                {{ formatTime(playTime) }} / {{ formatTime(totalTime) }}
              </div>
              <div v-if="demoVideo" class="text-end">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      v-on="on"
                      @click="openOriginalInNewTab"
                      class="open-original ml-2"
                    >
                      mdi-open-in-new
                    </v-icon>
                  </template>
                  <small>Open Original Proof</small>
                </v-tooltip>
              </div>
            </div>
          </div>
        </v-row>
      </v-card-text>
    </v-card>
    <v-overlay v-else>
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="studentDetails" persistent>
      <StudentDetail
        @closeModal="showStudentDetails"
        :reference="reference"
        :face_frame="getImage"
      />
    </v-dialog>
  </v-dialog>
</template>

<script>
import axios from "axios";
import StudentDetail from "../pages/Multiversity/StudentDetail.vue";
export default {
  components: { StudentDetail },
  props: {
    videos: {
      type: Array,
      required: true,
    },
    reference: String,
    session: String,
    intruder: String,
    engagement: String,
    face_frame: String,
  },
  data() {
    return {
      VideoDialog: false,
      isPlaying: true,
      playbackSpeed: 1,
      videosLoaded: false,
      studentDetails: false,
      playTime: 0,
      totalTime: 0,
      speed: 1,
      metadataLoadedCount: 0,
      demoVideo: false,
      videoUrl: `https://fms.facia.ai/backend/api/admin/get-ml-data-video/${this.session}`,
      loading: false,
    };
  },
  computed: {
    getImage() {
      return this.face_frame;
    },
  },
  watch: {
    videos: {
      immediate: true,
      handler() {
        if (this.videos.length > 0) {
          this.VideoDialog = true;
          this.videosLoaded = true;
          this.togglePlayback();

          const face_video = this.$refs.face_video;
          const screen_video = this.$refs.screen_video;

          // Check if face_video is defined before adding the event listener
          if (face_video) {
            face_video.addEventListener("loadedmetadata", () => {
              this.handleVideoMetadataLoaded();
            });
          }

          // Check if screen_video is defined before adding the event listener
          if (screen_video) {
            screen_video.addEventListener("loadedmetadata", () => {
              this.handleVideoMetadataLoaded();
            });
          }
        }
      },
    },
  },

  methods: {
    fetchVideo() {
      /**
       * Fetches the video from the specified video URL and sets it as the demo video.
       * If the fetch is successful, sets the demoVideo flag to true, creates a blob from
       * the fetched data, sets the video source to the blob URL, and updates loading and
       * control visibility states. If the fetch fails, sets the demoVideo flag to false,
       * sets the video source to the fallback URL, and updates loading and control visibility states.
       *
       * @method fetchDemoVideo
       */
      const fallbackVideoUrl = this.videos[1].url; // Define fallback video URL here
      axios
        .get(this.videoUrl, { responseType: "blob" })
        .then((response) => {
          if (response.status === 200) {
            this.demoVideo = true;
            const videoBlob = new Blob([response.data], { type: "video/mp4" });
            this.$refs.screen_video.src = URL.createObjectURL(videoBlob);
            this.loading = false;
            this.showControls = true;
          } else {
            throw new Error("File not found");
          }
        })
        .catch((error) => {
          console.error(error);
          this.demoVideo = false;
          this.$refs.screen_video.src = fallbackVideoUrl;
          this.loading = false;
          this.showControls = true;
        });
    },

    /**
     * Opens the original video in a new tab.
     *
     * @method openOriginalInNewTab
     */
    openOriginalInNewTab() {
      window.open(this.videos[1].url, "_blank");
    },

    /**
     * Closes the dialog by emitting a "close-dialog" event.
     *
     * @method closeDialog
     */
    closeDialog() {
      this.$emit("close-dialog");
    },

    /**
     * Shows or hides the student details based on the provided value.
     * If the value is "open" and there is an intruder, sets studentDetails to true.
     * Otherwise, sets studentDetails to false.
     *
     * @method showStudentDetails
     * @param {String} val - The value indicating whether to open or close the student details.
     */
    showStudentDetails(val) {
      if (val == "open" && this.intruder > 0) {
        this.studentDetails = true;
      } else {
        this.studentDetails = false;
      }
    },

    /**
     * Toggles the playback of both face and screen videos.
     * Pauses both videos if they are currently playing, and plays them if they are paused.
     *
     * @method togglePlayback
     */
    togglePlayback() {
      const face_video = this.$refs.face_video;
      const screen_video = this.$refs.screen_video;
      this.isPlaying ? face_video.pause() : face_video.play();
      this.isPlaying ? screen_video.pause() : screen_video.play();
      this.isPlaying = !this.isPlaying;
    },

    /**
     * Changes the playback speed of both face and screen videos.
     * Sets the playback speed to the specified speed, updates the playbackRate of both videos,
     * and updates the playbackSpeed state.
     *
     * @method changeSpeed
     * @param {Number} speed - The playback speed to set.
     */
    changeSpeed(speed) {
      this.speed = speed;
      const face_video = this.$refs.face_video;
      const screen_video = this.$refs.screen_video;
      face_video.playbackRate = speed;
      screen_video.playbackRate = speed;
      this.playbackSpeed = speed;
    },

    /**
     * Handles the event when video metadata is loaded.
     * Increments the metadataLoadedCount and checks if metadata is loaded for both face and screen videos.
     * If metadata is loaded for both videos, calculates the total time, updates the progress, and calls updateProgress.
     *
     * @method handleVideoMetadataLoaded
     */
    handleVideoMetadataLoaded() {
      this.metadataLoadedCount++;
      if (this.metadataLoadedCount === 2) {
        const face_video = this.$refs.face_video;
        const screen_video = this.$refs.screen_video;
        const face_video_duration = !isNaN(face_video.duration)
          ? face_video.duration
          : 0;
        const screen_video_duration = !isNaN(screen_video.duration)
          ? screen_video.duration
          : 0;
        this.totalTime = Math.floor(
          Math.max(face_video_duration, screen_video_duration)
        );
        this.updateProgress(); // Update progress once total time is calculated
      }
    },

    /**
     * Handles the event when the video ends.
     * Sets isPlaying to false and updates the progress.
     *
     * @method handleVideoEnded
     */
    handleVideoEnded() {
      this.isPlaying = false;
      this.updateProgress(); // Update progress when video ends
    },

    /**
     * Updates the playTime based on the current time of the video being played.
     * Calls updateProgressSmoothly to update progress smoothly during video playback.
     *
     * @method updateTime
     * @param {Event} event - The event containing the current time of the video.
     */
    updateTime(event) {
      this.playTime = Math.floor(event.target.currentTime);
      this.updateProgressSmoothly(); // Update progress smoothly during video playback
    },

    /**
     * Updates the progressPercentage based on the playTime and totalTime.
     *
     * @method updateProgress
     */
    updateProgress() {
      this.progressPercentage = (this.playTime / this.totalTime) * 100;
    },

    /**
     * Updates the progressPercentage smoothly during video playback.
     * Cancels the previous animation frame request, calculates the progress percentage,
     * and requests a new animation frame until the progressPercentage reaches 100%.
     *
     * @method updateProgressSmoothly
     */
    updateProgressSmoothly() {
      cancelAnimationFrame(this.progressAnimationFrameId);
      const update = () => {
        this.progressPercentage = (this.playTime / this.totalTime) * 100;

        if (this.progressPercentage < 100) {
          this.progressAnimationFrameId = requestAnimationFrame(update);
        }
      };
      this.progressAnimationFrameId = requestAnimationFrame(update);
    },

    /**
     * Formats the given time in seconds into the format MM:SS.
     *
     * @method formatTime
     * @param {Number} seconds - The time in seconds to be formatted.
     * @returns {String} The formatted time in the format MM:SS.
     */
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}:${
        remainingSeconds < 10 ? "0" : ""
      }${remainingSeconds}`;
    },
  },
};
</script>

<style scoped>
.title {
  height: 50px;
  border: 1px solid black;
  border-radius: 4px;
  width: auto;
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: center;
}

.video-container {
  width: 100%;
  margin: 0;
}

.video-container-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 100%;
  height: 100%;
}

.text-container {
  text-decoration: underline;
  font-size: large;
}

.cross-icon-div {
  position: absolute;
  top: -10px;
  right: -7px;
  z-index: 2;
  overflow: visible;
}

.cross-icon-parent-div {
  position: relative;
}

:deep .v-dialog {
  overflow: visible;
  border-radius: 4px;
  margin: 24px;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  z-index: inherit;
  outline: none;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.rounded-screen-video {
  border: 1px solid black;
  border-radius: 8%;
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.red-color {
  color: red;
}

.id_txt {
  color: rgba(0, 0, 0, 0.6);
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: bolder;
  line-height: 1.375rem;
  letter-spacing: 0.0071428571em;
}

.sub-txt {
  color: #0a2540;
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0.0071428571em;
}

.custom-btn {
  width: 169px;
  height: 36.058px;
  flex-shrink: 0;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* ensure it's on top of other content */
}

.processing-message {
  margin-top: 16px; /* adjust spacing as needed */
  font-size: 16px;
  color: #333; /* adjust color as needed */
}
</style>
