<template>
  <div class="custom-date-filter">
    <v-menu bottom left nudge-bottom="40" v-model="openSelectMenu">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :class="[
            'font-12 mr-0 fw-500 letter-space secondary--text text-transform-none',
            isStat ? 'stat-filters' : 'search-filters-date',
          ]"
          class="filter-btn"
          v-bind="attrs"
          v-on="on"
          outlined
          :rounded="isStat"
          width="auto"
          height="30px"
        >
          <slot>
            <v-icon class="mr-1">mdi-calendar-check</v-icon>
            Select date
            <v-icon class="mr-1">mdi-chevron-down</v-icon>
          </slot>
        </v-btn>
      </template>
      <v-list>
        <v-list-item-group
          @change="typeChanged"
          color="primary"
          active-class="active-item"
          :value="type"
        >
          <v-list-item
            class="custom-date-filters"
            v-for="n in dateFilters"
            :key="n.value"
            :value="n.value"
          >
            <v-list-item-title class="font-12 fw-400 letter-space">
              <v-icon class="font-16" v-if="type === n.value">
                mdi-check
              </v-icon>
              {{ n.name }}
            </v-list-item-title>
          </v-list-item>
          <div v-if="!billing">
            <v-divider></v-divider>
          </div>
          <v-list-item v-if="!billing" class="custom-date-filters">
            <v-list-item-title>
              <v-menu
                v-model="menu"
                top
                right
                :close-on-content-click="false"
                nudge-left="307"
                nudge-bottom="36"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    text
                    class="font-12 text-transform-none date-btn fw-400 pa-0 letter-space secondary--text"
                  >
                    <v-icon class="font-16" color="primary" v-if="selectDate">
                      mdi-check
                    </v-icon>
                    <v-icon
                      class="mr-1 font-14"
                      :class="{ 'primary--text': selectDate }"
                      >mdi-calendar</v-icon
                    >
                    <span
                      class="font-12 fw-400 text-capitalize letter-space"
                      :class="{ 'primary--text': selectDate }"
                      >Custom Date</span
                    >
                  </v-btn>
                </template>
                <v-date-picker
                  range
                  flat
                  no-title
                  color="#249DA8"
                  v-model="dates"
                  class="date-picker"
                >
                  <div class="float-right pt-4">
                    <span
                      class="font-12 pr-2 secondary--text fw-600 text-transform-none cursor-pointer"
                      @click="clearSearch"
                      >Clear</span
                    >
                    <span
                      class="font-12 pr-4 secondary--text fw-600 text-transform-none cursor-pointer"
                      @click="searchDate"
                      >Apply</span
                    >
                  </div>
                  <!-- </div> -->
                </v-date-picker>
              </v-menu>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      startTime: 0,
      endTime: 0,
      dates: [],
      menu: false,
      date: new Date().toISOString(),
      minDate: null,
      selectDate: false,
      openSelectMenu: false,
      startDate: null,
      endDate: null,
      otherDateFilters: [
        { name: "Today", value: "today" },
        { name: "Yesterday", value: "yesterday" },
        { name: "Last 7 days", value: "seven_days" },
        { name: "This Month", value: "this_month" },
        { name: "Last month", value: "last_month" },
        { name: "Last 3 months", value: "three_month" },
        { name: "Last 6 months", value: "six_month" },
        { name: "Last year", value: "last_year" },
        { name: "All time", value: "all_time" },
      ],
      billingDateFilters: [
        { name: "This Month", value: "this_month" },
        { name: "Last month", value: "last_month" },
        { name: "Last 3 months", value: "three_month" },
        { name: "Last 6 months", value: "six_month" },
        { name: "Last year", value: "last_year" },
        { name: "All time", value: "all_time" },
      ],
    };
  },
  computed: {
    dateFilters() {
      return this.billing ? this.billingDateFilters : this.otherDateFilters;
    },
  },
  watch: {
    dates: function () {
      this.startDate = this.dates[0];
      this.minDate = this.startDate;
    },
    display() {
      this.$emit("displayChange", this.display);
    },
    pageNumber() {
      this.$emit("pageChange", this.pageNumber);
    },
  },
  methods: {
    /**
     * Handles the change of the selected type. Resets the date selection and filters
     * the date options based on the selected type. Emits a "typeChanged" event with the matching date filter.
     *
     * @method typeChanged
     * @param {String} type - The selected type to filter the date options.
     */
    typeChanged(type) {
      this.selectDate = false;
      this.dates = [];
      this.dateFilters.forEach((date) => {
        if (date.value == type) {
          this.$emit("typeChanged", date);
        }
      });
    },

    /**
     * Clears the current date selection and search menu state. Emits a "clearSearch" event with an empty dates array.
     *
     * @method clearSearch
     */
    clearSearch() {
      this.selectDate = false;
      this.menu = false;
      this.dates = [];
      this.$emit("clearSearch", this.dates);
    },

    /**
     * Initiates a search based on the selected dates. If there are dates selected, it updates the menu state
     * and emits a "dateChange" event with the selected dates. If only one date is selected, it emits the same date twice.
     *
     * @method searchDate
     */
    searchDate() {
      if (this.dates.length > 0) {
        this.openSelectMenu = false;
        this.menu = false;
        this.selectDate = true;

        if (this.dates.length === 1) {
          this.$emit("dateChange", [this.dates[0], this.dates[0]]);
        } else {
          this.$emit("dateChange", this.dates);
        }
      }
    },
  },
  props: {
    isStat: {
      default: false,
      type: Boolean,
    },
    type: null,
    billing: null,
  },
};
</script>

<style>
/* date filter */
.stat-filters {
  border: 1px dashed rgba(137, 144, 162, 0.3) !important;
}
.cursor-pointer {
  cursor: pointer;
}
.custom-date-filters {
  min-height: 30px !important;
}
.custom-date-filters .v-list-item__title {
  color: #8990a2;
}
.active-item .v-list-item__title {
  color: #249da8 !important;
}
.custom-date-filters::before {
  background-color: #ffffff !important;
}
.date-btn:hover {
  background-color: #ffffff !important;
}
.date-btn::before {
  background-color: #ffffff !important;
}
.date-picker-time .v-input__control .v-input__slot {
  min-height: 24px !important;
  height: 24px !important;
  width: 70px !important;
  margin: auto !important;
}
.date-picker-time fieldset {
  border: 1px solid #e4e5ed;
  border-radius: 4px;
}
.date-picker .v-card__actions {
  display: block;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
}
.date-picker-time .v-input__control .v-input__slot .v-text-field__slot input {
  color: #8990a2 !important;
}
.date-picker .v-date-picker-table {
  height: 210px !important;
}
.filter-btn {
  border: 1px solid #e4e5ed;
}
</style>
