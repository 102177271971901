<template>
  <div
    :class="
      user
        ? 'user-table-outline'
        : 'table-outline' || merchant
        ? 'user-table-outline'
        : 'table-outline'
    "
  >
    <v-data-table
      :headers="customHeaders"
      :items="data"
      class="greyColor--text"
      hide-default-footer
      hide-default-header
      :loading="loading"
      :mobile-breakpoint="0"
    >
      <template v-slot:header="{ props: { headers } }">
        <thead>
          <tr>
            <th
              v-for="header in headers"
              :key="header.value"
              :width="header.width"
              :class="header.class"
            >
              <div class="header-container">
                <div class="header-text">{{ header.text }}</div>
                <span
                  v-if="headersSort[header.value] && !loading"
                  class="sort-icons"
                >
                  <i
                    v-if="headersSort[header.value].sort_desc"
                    @click="sorting('desc', header.value)"
                    class="mdi mdi-arrow-down primary--text cursor-pointer"
                  ></i>
                  <i
                    v-if="headersSort[header.value].sort_asc"
                    @click="sorting('asc', header.value)"
                    class="mdi mdi-arrow-up primary--text cursor-pointer"
                  ></i>
                </span>
              </div>
            </th>
          </tr>
        </thead>
      </template>
      <template
        v-for="customSlot in customSlots"
        v-slot:[`item.${customSlot}`]="{ item }"
      >
        <slot
          class="custom-slot-for-data-table"
          :name="customSlot"
          :item="item"
        >
        </slot>
      </template>
      <template v-slot:body.prepend>
        <slot name="body-prepend"> </slot>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    customHeaders: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    roles: {
      type: Array,
      default: () => [],
    },
    customSlots: {
      type: Array,
      default: () => {
        return [];
      },
    },
    user: {
      type: Boolean,
      default: false,
    },
    merchant: {
      type: Boolean,
      default: false,
    },
    student: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
      headersSort: {
        lecture_count: { sort_asc: true, sort_desc: false },
        exam_count: { sort_asc: true, sort_desc: false },
        ended_at: { sort_asc: true, sort_desc: false },
        started_at: { sort_asc: true, sort_desc: false },
        created_at: { sort_asc: true, sort_desc: false },
        updated_at: { sort_asc: true, sort_desc: false },
      },
    };
  },
  methods: {
    /**
     * Sorts the data based on the specified criteria and updates the sorting states for the headers.
     * It sets the sorting order for the specified header and emits a "groupBy" event with the sorting data.
     *
     * @method sorting
     * @param {String} sortBy - The order to sort by ('asc' for ascending, 'desc' for descending).
     * @param {String} sortOn - The header to sort on (e.g., 'lecture_count', 'exam_count').
     */
    sorting(sortBy, sortOn) {
      (this.headersSort = {
        lecture_count: { sort_asc: true, sort_desc: false },
        exam_count: { sort_asc: true, sort_desc: false },
        ended_at: { sort_asc: true, sort_desc: false },
        started_at: { sort_asc: true, sort_desc: false },
        created_at: { sort_asc: true, sort_desc: false },
        updated_at: { sort_asc: true, sort_desc: false },
      }),
        // Update the sort variables for the specific header
        (this.headersSort[sortOn].sort_desc = sortBy === "asc");
      this.headersSort[sortOn].sort_asc = sortBy === "desc";

      const data = {
        sortBy,
        sortOn,
      };
      this.$emit("groupBy", data);
    },
  },
};
</script>
<style scoped>
.header-container {
  display: flex;
}

.header-text {
  text-align: left !important;
}
.sort-icons {
  margin-left: 8px;
}
.icon-sort:before {
  content: "\F0045";
}
.icon-sort {
  display: inline-block;
  font: normal normal normal 24px/1 Material Design Icons;
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
}
</style>