<template>
  <div>
    <v-card color="appBackGround" elevation="0" style="height: 785px">
      <v-card-title class="greyColor--text cus-h font-15 fw-600 pa-6">
        <div
          height="30px"
          class="font-12 cus-ml ml-3 fw-500 secondary--text student-search-filters student-text-field-filter"
        >
          {{ studentName }}
        </div>
        <v-spacer></v-spacer>
        <div class="student-text-field-filter">
          <v-text-field
            v-model="studentName"
            type="text"
            outlined
            hide-details
            dense
            height="30px"
            placeholder="Search by Name"
            prepend-inner-icon="mdi-magnify"
            class="font-12 cus-ml mr-3 fw-500 secondary--text student-search-filters"
            v-if="checkApiType !== 'single'"
          ></v-text-field>
        </div>
        <custom-date-filter
          @typeChanged="typeChanged"
          :type="type"
          @clearSearch="dateCleared"
          @dateChange="dateChanged"
          v-if="checkApiType !== 'single'"
          class="student-text-field-filter mb-1 mr-3"
        >
          <span class="select-date" :class="name != null ? 'black--text' : ''">
            {{ name === null ? "Select Date Range" : name }}
          </span>
          <v-img class="student_calender_icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
            >
              <path
                fill="grey"
                d="M19 19H5V8h14m-3-7v2H8V1H6v2H5c-1.11 0-2 .89-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-1V1m-1 11h-5v5h5v-5Z"
              />
            </svg>
          </v-img>
        </custom-date-filter>
      </v-card-title>
      <DataTable
        :customHeaders="computedHeaders"
        :data="transactions"
        :loading="isLoading"
        :customSlots="[
          'face',
          'lecture_count',
          'exam_count',
          'status',
          'action',
          'name',
          'frame_id',
          'duration',
          'started_at',
          'ended_at',
          'exam_duration',
          'engagement',
          'intruder',
        ]"
        :student="true"
        @groupBy="groupBy"
      >
        <template v-slot:frame_id="{ item }">
          <div class="student-above-image student-thumbnail-image">
            <v-img
              class="student-thumbnail-image"
              :src="item.frame_id"
              alt="face"
              @click="openImageModal(item)"
            ></v-img>
          </div>
        </template>
        <template v-slot:duration="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                {{
                  formattedTime(item.started_at, item.ended_at) == "Pending"
                    ? "--"
                    : formattedTime(item.started_at, item.ended_at)
                }}
              </span>
            </template>
            <span>{{ calculateDuration(item.started_at, item.ended_at) }}</span>
          </v-tooltip>
        </template>
        <template v-slot:exam_duration="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                {{ convertToReadableTime(item.exam_duration) }}
              </span>
            </template>
            <span>{{ item.exam_duration }}</span>
          </v-tooltip>
        </template>
        <template v-slot:started_at="{ item }">
          <v-tooltip max-width="120" top>
            <template v-slot:activator="{ on }">
              <div class="date-time" v-on="on">
                <span class="show-time-created">{{
                  item.started_at
                    ? formatTransactionTime(item.started_at)
                    : "--"
                }}</span>
                <span class="show-day-created">{{
                  formatTransactionDay(item.started_at)
                }}</span>
              </div>
            </template>
            <span>{{
              item.started_at | dateTimeConversionClientTimeZone
            }}</span>
          </v-tooltip>
        </template>
        <template v-slot:ended_at="{ item }">
          <v-tooltip max-width="120" top>
            <template v-slot:activator="{ on }">
              <div class="date-time" v-on="on">
                <span class="show-time-created">{{
                  item.ended_at ? formatTransactionTime(item.ended_at) : "--"
                }}</span>
                <span class="show-day-created">{{
                  item.ended_at ? formatTransactionDay(item.ended_at) : ""
                }}</span>
              </div>
            </template>
            <span>{{ item.ended_at | dateTimeConversionClientTimeZone }}</span>
          </v-tooltip>
        </template>
        <template v-if="checkApiType !== 'single'" v-slot:name="{ item }">
          {{ item.first_name }} {{ item.last_name }}
        </template>
        <template v-slot:engagement="{ item }">
          <div class="text-center">
            <v-chip
              v-if="item.engagement"
              class="custom-chip"
              :class="getEngagementClass(item)"
            >
              <strong>{{ item.engagement + "%" }}</strong>
            </v-chip>
            <p v-else>--</p>
          </div>
        </template>
        <template v-slot:intruder="{ item }">
          <div class="text-center">
            <v-chip
              v-if="item.intruder > 0"
              class="custom-chip status-red"
              @click="showStudentDetails(item)"
            >
              <strong>Yes</strong>
            </v-chip>
            <p v-else>--</p>
          </div>
        </template>
        <template v-slot:status="{ item }">
          <div>
            <v-chip class="custom-chip" :class="getStatusClass(item)">
              {{ item.status }}
            </v-chip>
          </div>
        </template>
        <template v-slot:action="{ item }">
          <div class="d-flex">
            <div @click="examVideo(item)" class="cursor-pointer">
              <v-tooltip
                top
                v-if="
                  item.status == 'completed' &&
                  item.is_videos_compiled == false &&
                  !iconLoader
                "
              >
                <template v-slot:activator="{ on }">
                  <img
                    height="23"
                    src="../../../assets/icons/video.svg"
                    alt=""
                    v-on="on"
                  />
                </template>
                <span>Processing..</span>
              </v-tooltip>
              <v-progress-circular
                v-else-if="
                  item.status == 'completed' &&
                  item.is_videos_compiled == false &&
                  iconLoader
                "
                indeterminate
                size="20"
              ></v-progress-circular>
              <img
                v-else-if="
                  item.status == 'completed' && item.is_videos_compiled == true
                "
                height="23"
                src="../../../assets/icons/video.svg"
                alt=""
              />
            </div>
            <!-- <div>
            <v-icon>mdi-information-outline</v-icon>
          </div> -->
          </div>
        </template>
        <template v-slot:lecture_count="{ item }">
          <div
            class="lecture-count"
            @click="DetailListing('lecture', item.hash_id)"
          >
            {{ item.lecture_count }}
          </div>
        </template>
        <template v-slot:exam_count="{ item }">
          <div class="exam_count" @click="DetailListing('exam', item.hash_id)">
            {{ item.exam_count }}
          </div>
        </template>
      </DataTable>
      <div class="student-pagination-controls">
        <span class="student-show-pagination-numbers"
          >Viewing {{ getViewingRange() }}</span
        >
        <div>
          <v-btn
            @click="prevPage"
            :disabled="paginate.page === 1"
            class="text-capitalize student-pervious-pagination-btn"
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.9991 19L9.83911 14C9.56672 13.7429 9.34974 13.433 9.20142 13.0891C9.0531 12.7452 8.97656 12.3745 8.97656 12C8.97656 11.6255 9.0531 11.2548 9.20142 10.9109C9.34974 10.567 9.56672 10.2571 9.83911 10L14.9991 5"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Previous
          </v-btn>
          <v-btn
            @click="nextPageButton"
            :disabled="paginate.page === lastPage"
            class="student-next-pagination-btn ml-2 text-capitalize"
          >
            Next
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <path
                d="M4.28257 9.74991C4.17952 9.75196 4.07843 9.71954 3.99327 9.65714C3.90812 9.59473 3.84312 9.50543 3.80727 9.40158C3.77142 9.29774 3.76649 9.18448 3.79317 9.07749C3.81984 8.9705 3.8768 8.87507 3.95616 8.80439L6.99332 6.01018L3.95428 3.21596C3.89876 3.17246 3.85223 3.11707 3.81764 3.05328C3.78304 2.98949 3.76112 2.91868 3.75326 2.84533C3.74541 2.77198 3.75178 2.69767 3.77199 2.6271C3.7922 2.55653 3.82581 2.49123 3.87068 2.43532C3.91555 2.3794 3.97073 2.33409 4.03271 2.30225C4.0947 2.2704 4.16216 2.25271 4.23083 2.25029C4.2995 2.24787 4.36789 2.26077 4.43169 2.28818C4.49548 2.31559 4.5533 2.35692 4.60149 2.40955L8.07575 5.60092C8.13039 5.65108 8.17426 5.71337 8.20434 5.7835C8.23442 5.85363 8.25 5.92993 8.25 6.00715C8.25 6.08438 8.23442 6.16068 8.20434 6.23081C8.17426 6.30094 8.13039 6.36323 8.07575 6.41338L4.60148 9.61483C4.51344 9.69907 4.40042 9.74694 4.28257 9.74991Z"
                fill="white"
              />
            </svg>
          </v-btn>
        </div>
      </div>
    </v-card>
    <v-dialog
      v-model="imageModal"
      max-width="700px"
      max-height="60vh"
      overlay-opacity="0.8"
    >
      <v-carousel hide-delimiters reverse :show-arrows="imageArray.length > 1">
        <v-carousel-item
          v-for="(item, i) in imageArray"
          :key="i"
          :src="item"
          active-class="image-style"
          class="modal-img"
        ></v-carousel-item>
      </v-carousel>
    </v-dialog>
    <v-dialog v-model="studentDetails" persistent>
      <StudentDetail
        @closeModal="showStudentDetails"
        :reference="this.reference_id"
        :face_frame="this.face_frame"
        :student_details="studentDetails"
      />
    </v-dialog>
    <VideoDialog
      :reference="this.reference_id"
      :session="this.session_id"
      :intruder="this.intruders"
      :face_frame="this.face_frame"
      :engagement="this.engagements"
      :videos="examVideoArr"
      @close-dialog="closeDialog"
      v-if="showVideo"
    />
  </div>
</template>

<script>
import moment from "moment";
import StudentDetail from "./StudentDetail.vue";
import DataTable from "../../common/DataTable.vue";
import ApiService from "@/services/Api";
import CustomDateFilter from "../../common/filters/CustomDateFilter.vue";
import { showErrorMessage } from "../../../utils/showError";
import VideoDialog from "../../common/VideoDialogue.vue";
import debounce from "lodash.debounce";
import { ADMIN_API_URL } from "@/utils/env.js";
export default {
  name: "ExamPortal",
  components: {
    DataTable,
    CustomDateFilter,
    VideoDialog,
    StudentDetail,
  },
  data() {
    return {
      imageArray: [],
      sortOn: "",
      face_frame: "",
      reference_id: "",
      session_id: "",
      sortBy: "",
      checkApiType: "",
      modalImageUrl: "",
      reference_no: "",
      intruders: "",
      engagements: "",
      studentDetails: false,
      imageModal: false,
      baseUrl: ADMIN_API_URL,
      name: "All Time",
      dialog: false,
      deleteModal: false,
      deleteReference: "",
      deleteReason: "",
      reasonRule: [
        (value) => !!value || "Required.",
        (value) => (value || "").length <= 250 || "Max 250 characters",
      ],
      btnLoader: false,
      isLoading: false,
      loading: false,
      selectedTitle: "",
      status: null,
      iconLoader: false,
      title: "",
      startDate: null,
      endDate: null,
      type: "all_time",
      transaction_type: null,
      studentName: "",
      deleted_transaction: null,
      paginate: {
        page: 1,
        itemsPerPage: 10,
      },
      total: null,
      lastPage: null,
      showVideo: false,
      examVideoArr: [],
      headers: [
        {
          text: "Lecture Name",
          align: "start",
          value: "lecture_name",
        },
        ...(this.checkApiType !== "single"
          ? [{ text: "Student Name", value: "name" }]
          : []),
        { text: "Face", value: "frame_id", sort_asc: true, sort_desc: false },
        {
          text: "Started At",
          value: "started_at",
          sort_asc: true,
          sort_desc: false,
        },
        {
          text: "Ended At",
          value: "ended_at",
          sort_asc: true,
          sort_desc: false,
        },
        { text: "Engagement", value: "engagement" },
        { text: "Impersonator", value: "intruder" },
        { text: "Time Spend", value: "duration" },
        { text: "Lecture Duration", value: "exam_duration" },
        {
          text: "Status",
          value: "status",
          key: "lecture-count",
          sort_asc: true,
          sort_desc: false,
        },
        { text: "Video", value: "action" },
      ],

      transactions: [],
      statusFilters: [
        { name: "All", value: "all" },
        { name: "Initiated", value: "INITIATED" },
        { name: "Pending", value: "PENDING" },
        { name: "In Process", value: "INPROCESS" },
        { name: "Accepted", value: "ACCEPTED" },
        { name: "Liveness Passed", value: "ACCEPTED" },
        { name: "Declined", value: "DECLINED" },
        { name: "Timeout", value: "TIMEOUT" },
        { name: "Face Not Found", value: "NOT_FOUND" },
      ],
      deletedTransactionFilter: [
        { name: "All", value: "all" },
        { name: "Deleted", value: "true" },
        { name: "Not Deleted", value: "false" },
      ],
      transactionTypeFilter: [
        { name: "All", value: "all" },
        { name: "Liveness", value: "liveness" },
        { name: "Face Match", value: "photo_id_match" },
        { name: "Face Search", value: "face_search" },
        { name: "Face Enrollment", value: "face_enrollment" },
        { name: "Age Estimation", value: "age_estimation" },
      ],
    };
  },
  computed: {
    computedHeaders() {
      if (this.checkApiType !== "single") {
        // If checkApiType is not 'single', include the 'name' header
        return this.headers;
      } else {
        // If checkApiType is 'single', exclude the 'name' header
        return this.headers.filter((header) => header.value !== "name");
      }
    },
  },
  watch: {
    sortBy: {
      handler() {
        this.getTransactions();
      },
      deep: true,
    },
    reference_no: {
      handler: debounce(function () {
        this.getTransactionsOnPageBasis();
      }, 500),
      deep: true,
    },
    studentName: {
      handler: debounce(function () {
        this.getTransactionsOnPageBasis();
      }, 500),
      deep: true,
    },
    $route: {
      immediate: true, // This will trigger the watcher when the component is initially created.
      handler() {
        if (this.$route.path.includes("lecture")) {
          this.getTransactions();
        }
      },
    },
    type: {
      handler() {
        this.getTransactionsOnPageBasis();
      },
    },
    paginate: {
      handler() {
        this.getTransactions();
      },
      deep: true,
    },
  },
  mounted() {
    const queryParm = this.$route.query;
    if (Object.keys(queryParm).length !== 0) {
      this.checkApiType = "single";
    }
  },
  methods: {
    /**
     * Calculates the duration between two timestamps in the format HH:mm:ss.
     * @param {string} started_at - The start timestamp.
     * @param {string} ended_at - The end timestamp.
     * @returns {string} The duration in the format HH:mm:ss.
     */
    calculateDuration(started_at, ended_at) {
      if (!ended_at) {
        return "Pending";
      }

      const startTime = new Date(started_at).getTime();
      const endTime = new Date(ended_at).getTime();
      const difference = Math.abs(endTime - startTime);

      const hours = Math.floor(difference / (1000 * 60 * 60))
        .toString()
        .padStart(2, "0");
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
        .toString()
        .padStart(2, "0");
      const seconds = Math.floor((difference % (1000 * 60)) / 1000)
        .toString()
        .padStart(2, "0");

      return `${hours}:${minutes}:${seconds}`;
    },

    /**
     * Formats the duration between two timestamps in a human-readable format.
     * @param {string} started_at - The start timestamp.
     * @param {string} ended_at - The end timestamp.
     * @returns {string} The formatted duration.
     */
    formattedTime(started_at, ended_at) {
      if (!ended_at) {
        return "Pending";
      }

      const startTime = new Date(started_at).getTime();
      const endTime = new Date(ended_at).getTime();
      const difference = Math.abs(endTime - startTime);

      const hours = Math.floor(difference / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      if (hours > 0) {
        return `${hours} hour${hours > 1 ? "s" : ""}`;
      } else if (minutes > 0) {
        return `${minutes} minute${minutes > 1 ? "s" : ""}`;
      } else {
        return `${seconds} second${seconds > 1 ? "s" : ""}`;
      }
    },

    /**
     * Retrieves exam videos for a given item and sets them for display.
     * @param {Object} item - The item containing exam video information.
     */
    examVideo(item) {
      this.reference_id = item.reference_id;
      this.session_id = item.session_id;
      this.intruders = item.intruder;
      this.engagements = item.engagement;
      if (item.status == "completed" && item.is_videos_compiled == false) {
        this.iconLoader = true;
        let data = {
          reference_id: item.reference_id,
        };
        ApiService.PostRequest("/merchant/student-create-video", data)
          .then((res) => {
            this.iconLoader = false;
            this.showVideo = true;
            this.examVideoArr = [
              { title: "", url: res.data.face_video_id },
              { title: "", url: res.data.screen_video_id },
            ];
          })
          .catch((error) => {
            showErrorMessage(error);
          });
      } else {
        this.showVideo = true;
        this.examVideoArr = [
          { title: "", url: item.face_video_id },
          { title: "", url: item.screen_video_id },
        ];
      }
    },

    /**
     * Sets the student details to display based on the provided item.
     * @param {Object} item - The item containing student details.
     */
    showStudentDetails(item) {
      if (item) {
        this.reference_id = item.reference_id;
        this.face_frame = item.frame_id;
        this.studentDetails = true;
      } else {
        this.studentDetails = false;
      }
    },

    /**
     * Closes the dialog for displaying videos.
     */
    closeDialog() {
      this.showVideo = false;
    },

    /**
     * Sets the sorting parameters based on the provided data.
     * @param {Object} data - The sorting data containing sortBy and sortOn properties.
     */
    groupBy(data) {
      this.sortBy = data.sortBy;
      this.sortOn = data.sortOn;
    },

    /**
     * Returns a class object based on the status of the item.
     * @param {Object} item - The item containing status information.
     * @returns {Object} The class object representing the status.
     */
    getStatusClass(item) {
      return {
        "custom-age":
          item.status === "ACCEPTED" && item.type === "age_estimation",
        "status-pending": item.status === "pending",
        "status-declined": item.status === "DECLINED",
        "status-in-process": item.status === "INPROCESS",
        "status-initiated": item.status === "INITIATED",
        "status-timeouts": item.status === "TIMEOUT",
        "status-not-found": item.status === "NOT_FOUND",
        "status-accepted": item.status === "completed",
      };
    },

    /**
     * Returns a class object based on the engagement level of the item.
     * @param {Object} item - The item containing engagement information.
     * @returns {Object} The class object representing the engagement level.
     */
    getEngagementClass(item) {
      return {
        "status-green": item.engagement >= 80,
        "status-yellow": item.engagement < 80 && item.engagement >= 60,
        "status-red": item.engagement < 60,
      };
    },

    /**
     * Opens the image modal and sets the image source.
     * @param {Object} item - The item containing image information.
     */
    openImageModal(item) {
      this.imageArray = [];
      let img1;
      if (item && item.frame_id) {
        img1 = `${item.frame_id}`;
        this.imageArray.push(img1);
      }
      // Set the dynamic image source for the modal
      this.imageModal = true;
    },

    /**
     * Calculates and returns the viewing range based on pagination.
     * @returns {string} The viewing range text.
     */
    getViewingRange() {
      const start = (this.paginate.page - 1) * this.paginate.itemsPerPage + 1;
      const end = Math.min(start + this.paginate.itemsPerPage - 1, this.total);
      return `${end} out of ${this.total}`;
    },

    /**
     * Handles the click event for the next page button.
     * Increments the page number and retrieves transactions.
     */
    nextPageButton() {
      if (this.paginate.page < this.lastPage) {
        this.paginate.page += 1;
        this.getTransactions();
      }
    },

    /**
     * Handles the click event for the previous page button.
     * Decrements the page number and retrieves transactions.
     */
    prevPage() {
      if (this.paginate.page > 1) {
        this.paginate.page -= 1;
        this.getTransactions();
      }
    },

    /**
     * Retrieves transactions based on the current page.
     * Resets the page number if it's not already at the first page.
     */
    getTransactionsOnPageBasis() {
      if (this.paginate.page !== 1) this.paginate.page = 1;
      else this.getTransactions();
    },

    /**
     * Retrieves the content type of a given URL by making a HEAD request.
     * @param {string} url - The URL of the content.
     * @returns {Promise<string>} A promise that resolves to the content type.
     */
    getContentType(url) {
      if (url === null) {
        return Promise.resolve(null);
      }
      return fetch(url, { method: "HEAD" })
        .then((response) => {
          const contentType = response.headers.get("Content-Type");
          if (contentType && contentType.startsWith("image/")) {
            return "image";
          } else if (contentType && contentType.startsWith("video/")) {
            return "video";
          } else {
            return "unsupported";
          }
        })
        .catch((error) => {
          console.error("Error fetching the URL:", error);
          return "error";
        });
    },

    /**
     * Retrieves transactions based on the current page and query parameters.
     * Updates the state with the fetched data.
     */
    getTransactions() {
      const queryParm = this.$route.query;
      if (Object.keys(queryParm).length > 0) {
        this.studentName = queryParm.studentName;
        this.type = queryParm.type;
        this.startDate = queryParm.start_date;
        this.endDate = queryParm.end_date;
      }
      this.isLoading = true;

      let data = {
        pagination: this.paginate.itemsPerPage,
        page: this.paginate.page,
        student_name: this.studentName,
        start_date: this.startDate,
        end_date: this.endDate,
        hash_id: queryParm.id,
        detail_type: queryParm.name ? queryParm.name : "lecture",
        type: queryParm.type ? queryParm.type : this.type,
        reference: this.reference_no,
        sort_by: this.sortBy,
        sort_on: this.sortOn,
      };
      let url;
      if (
        this.checkApiType !== "single" &&
        Object.keys(queryParm).length === 0
      ) {
        url = "/merchant/all-students-listing";
      } else {
        url = "/merchant/student-detail";
        // url = '/merchant/all-students-listing';
      }
      ApiService.PostRequest(url, data)
        .then((response) => {
          this.isLoading = false;
          {
            const returnedData = response.data.result;
            Promise.all(
              returnedData.data.map((item) =>
                this.getContentType(item.quick_liveness_id_url)
              )
            ).then((contentTypes) => {
              this.transactions = returnedData.data.map((item, index) => {
                return {
                  ...item,
                  contentType: contentTypes[index],
                };
              });
            });
            this.total = returnedData.total;
            this.lastPage = returnedData.last_page;
          }
        })
        .catch((error) => {
          showErrorMessage(error);
          if (error.response.status === 401) {
            this.$store.dispatch("logout");
            this.$store.dispatch("SET_LOGOUT");
            this.$router.push("/");
            this.isLoading = false;
          }
        });
    },

    /**
     * Handles the date change event.
     * Updates the start and end date, then retrieves transactions.
     * Resets the page number to 1.
     * @param {Array<Date>} changedDate - An array containing the start and end date.
     */
    dateChanged(changedDate) {
      this.type = "custom_range";
      if (changedDate[0] <= changedDate[1]) {
        this.startDate = changedDate[0];
        this.endDate = changedDate[1];
      } else if (changedDate[0] >= changedDate[1]) {
        this.startDate = changedDate[1];
        this.endDate = changedDate[0];
      } else if (changedDate[1] == undefined) {
        this.startDate = changedDate[0];
        this.endDate = "";
      } else {
        (this.startDate = ""), (this.endDate = "");
      }
      this.name = this.startDate + " " + this.endDate;
      this.paginate.page = 1;
      this.getTransactions();
    },

    /**
     * Handles the change of transaction type.
     * Updates the type and name, resets start and end dates, and fetches transactions based on the updated type.
     */
    typeChanged(type) {
      this.type = type.value;
      this.name = type.name;
      this.startDate = null;
      this.endDate = null;
      this.getTransactionsOnPageBasis();
    },

    /**
     * Clears the date and type filters.
     * Updates the name and type, then fetches transactions based on the updated type.
     */
    dateCleared() {
      this.name = null;
      this.type = null;
      this.getTransactionsOnPageBasis();
    },

    /**
     * Formats the transaction time into a human-readable format.
     * Calculates the time difference between the transaction time and the current time.
     * Returns a string representing the time difference in minutes ago, hours ago, or a specific time format.
     * @param {string} transactionTime - The transaction time string.
     * @returns {string} - A formatted string representing the time difference.
     */
    formatTransactionTime(transactionTime) {
      const userTimeZone = moment.tz.guess();
      const transactionMoment = moment.utc(transactionTime).tz(userTimeZone);

      const currentTime = moment();
      const duration = moment.duration(currentTime.diff(transactionMoment));
      const minutesAgo = duration.asMinutes();
      const hoursAgo = duration.asHours();

      if (minutesAgo < 60) {
        return `${Math.floor(minutesAgo)} minutes ago`;
      } else if (hoursAgo < 24) {
        return `${Math.floor(hoursAgo)} hours ago`;
      } else {
        return transactionMoment.format("HH:mm:ss A");
      }
    },

    /**
     * Formats the transaction day into a human-readable format.
     * Determines if the transaction occurred today, yesterday, or on a different date.
     * Returns a formatted string representing the transaction day.
     * @param {string} actualTime - The actual transaction time string.
     * @returns {string} - A formatted string representing the transaction day.
     */
    formatTransactionDay(actualTime) {
      // Assuming actualTime is a valid date string or Date object
      const today = moment().startOf("day");
      const createdDate = moment(actualTime);

      // Check if the date is today
      if (createdDate.isSame(today, "day")) {
        return "Today";
      }

      // Check if the date is yesterday
      const yesterday = moment().subtract(1, "days").startOf("day");
      if (createdDate.isSame(yesterday, "day")) {
        return "Yesterday";
      }

      // For older dates, format as 'DD / MMM / YYYY'
      const formattedDate = createdDate.format("DD / MMM / YYYY");
      return formattedDate;
    },

    /**
     * Converts a time string (HH:mm:ss) into a human-readable format.
     * Splits the time string into hours, minutes, and seconds, then constructs a readable time format.
     * Returns the readable time format.
     * @param {string} timeString - The time string to convert.
     * @returns {string} - The human-readable time format.
     */
    convertToReadableTime(timeString) {
      // Split the time string into hours, minutes, and seconds
      var timeParts = timeString.split(":");

      // Parse the parts into integers
      var hours = parseInt(timeParts[0]);
      var minutes = parseInt(timeParts[1]);
      var seconds = parseInt(timeParts[2]);

      // Convert excess seconds to minutes
      var extraMinutes = Math.floor(seconds / 60);
      minutes += extraMinutes;
      seconds = seconds % 60;

      // Construct the readable time format
      var readableTime = "";

      if (hours > 0) {
        readableTime += hours + " hour" + (hours > 1 ? "s" : "") + " ";
      } else if (minutes > 0 || hours > 0) {
        readableTime += minutes + " minute" + (minutes > 1 ? "s" : "") + " ";
      } else if (seconds > 0 || (hours == 0 && minutes == 0)) {
        readableTime += seconds + " second" + (seconds > 1 ? "s" : "");
      }

      return readableTime;
    },
  },
};
</script>

<style>
.modal-img .v-image__image--cover {
  background-size: contain;
}
.modal-img .v-carousel__item {
  background: #e9e9e9;
}
.normal-flag {
  -webkit-transform: scale(0.4) !important;
  transform: scale(0.4) !important;
}
.student_calender_icon {
  padding-top: 5px;
  padding-left: 10px;
}
.detail-btns {
  display: flex;
  justify-content: start;
  align-items: baseline;
}
.icon-background {
  background-color: #f2f4f9 !important;
  border-radius: 15px !important;
  color: #213cc8 !important;
}
.icon-delete {
  color: #ff0505 !important;
  border-radius: 15px !important;
}
.student-above-image {
  display: flex;
}
.student-thumbnail-image {
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 3px;
  cursor: pointer;
}
.background-color-custom {
  position: relative; /* Ensure relative positioning for absolute positioning inside */
}
.outer-circle {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: #eeeef3;
  display: flex;
  justify-content: center;
  align-items: center;
}
.outer-plus-icon {
  position: absolute;
  top: -1px;
  right: -4px;
  height: 8px;
  width: 10px;
  padding: 6px;
  border-radius: 50%;
  background-color: #eeeef3;
  border-top-right-radius: 90%;
  border-bottom-left-radius: 90%;
}
.set-plus-icon {
  position: absolute;
  top: 1px;
  right: 2px;
  height: 8px;
  background-color: #eeeef3;
  border-radius: 50%;
}
.set-age {
  position: relative;
  border: 1.5px solid #213cc8;
  border-radius: 50%;
  padding: 6px;
  background-color: #eeeef3;
  color: #213cc8;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.student-next-pagination-btn {
  border-radius: 6px !important;
  background: #213cc8 !important;
  height: 32px !important;
  padding: 10px !important;
  color: #fff !important;
  font-family: Inter !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.student-pervious-pagination-btn {
  border-radius: 6px !important;
  border: 1px solid #e3e3e3 !important;
  background: #fff !important;
  height: 32px !important;
  padding: 10px !important;
  color: #0a2540 !important;
  font-family: Inter !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.student-pagination-controls {
  display: flex;
  justify-content: space-between;
  padding: 51px 20px 27px 20px;
  align-items: center;
}
.student-show-pagination-numbers {
  color: #292b30;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.date-time {
  display: flex;
  flex-direction: column;
}
.show-time-created {
  color: #0a2540;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.show-day-created {
  color: #979797;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.student-search-filters-date {
  border: 1px solid #eaecf3 !important;
  border-radius: 6px;
}

.student-search-filters-date .v-icon {
  font-size: 18px !important;
  color: #a0a6b4 !important;
}

.student-search-filters-date::before {
  background-color: transparent !important;
}

.student-search-filters fieldset {
  border: 1px solid #eaecf3 !important;
  border-radius: 6px;
}

.student-search-filters .v-input__slot {
  min-height: 30px !important;
}
.v-menu__content {
  min-width: 160px !important;
}
.select-filter .v-input__slot {
  width: 120px !important;
}

.student-text-field-filter .v-input__slot {
  width: 165px !important;
}

.student-search-filters .v-icon {
  font-size: 18px !important;
  color: #a0a6b4 !important;
  padding-bottom: 8px;
  transform: none !important;
}

.student-search-filters .v-icon::before {
  color: #a0a6b4 !important;
}

.manu-custom-class .v-list-item__action {
  margin-right: 5px !important;
}

.manu-custom-class .v-list-item .v-list-item__content .v-list-item__title {
  font-size: 12px !important;
  font-weight: 400;
  color: #8990a2 !important;
}

.manu-custom-class .v-list-item__action {
  margin: 0px;
}

.v-input--selection-controls__input .v-icon {
  font-size: 18px !important;
  border-radius: 6px;
}

.manu-custom-class .v-list-item {
  min-height: 32px !important;
  padding: 0px 0px 0px 15px;
}

.manu-custom-class .v-list-item::before {
  background-color: #ffffff !important;
}

.v-menu__content {
  background: #ffffff !important;
  box-shadow: 0px 4px 14px rgba(137, 144, 162, 0.08);
  border-radius: 6px;
}

.student-search-filters .mdi-chart-line.v-icon {
  font-size: 15px !important;
}
.student-search-filters-deleted .v-input__slot {
  width: 140px !important;
}
.chip-styling {
  font-size: 12px !important;
  font-weight: 500;
}
.cus-h {
  line-height: 3rem !important;
}

@media (max-width: 496px) {
  .cus-ml {
    margin-left: -12px !important;
  }
}
@media (max-width: 390px) {
  .cus-m {
    margin-top: 7px;
  }
}
.deleted-chip {
  font-size: 10px !important;
  font-weight: 700;
  color: #e23b19 !important;
  margin-left: 15px;
}
.type-liveness {
  font-size: 12px !important;
  font-weight: 500;
  color: #4caf50 !important;
  background-color: #edf5ee !important;
}

.type-idscan {
  font-size: 12px !important;
  font-weight: 500;
  color: #7aa0da !important;
  background-color: #edf1f5 !important;
}
.details-header {
  padding-left: 30px !important;
}
.v-btn {
  text-transform: none !important;
}
.status-face-not-found-age {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #96700d !important;
  border-radius: 4px !important;
  background: rgba(238, 208, 128, 0.5) !important;
  padding: 3px 7px;
  font-style: normal !important;
  line-height: normal !important;
  display: inline-block; /* or display: block; */
  width: 104px;
}
.manu-custom-class .v-list-item__action {
  margin-right: 5px !important;
}

.manu-custom-class .v-list-item .v-list-item__content .v-list-item__title {
  font-size: 12px !important;
  font-weight: 400;
  color: #8990a2 !important;
}

.manu-custom-class .v-list-item__action {
  margin: 0px;
}

.manu-custom-class .v-list-item {
  min-height: 32px !important;
  padding: 0px 0px 0px 15px;
}
.lecture-count {
  color: #213cc8;
  text-decoration: underline;
}
.status-green {
  color: #1ea94e !important;
  background: rgba(69, 201, 114, 0.1) !important;
}
.status-yellow {
  color: #96700d !important;
  background: rgba(238, 208, 128, 0.5) !important;
}
.status-red {
  color: #cb1818 !important;
  background: #fde9e9 !important;
}
</style>
<style scoped>
:deep .v-dialog {
  overflow: visible;
  border-radius: 4px;
  margin: 24px;
  /* overflow-y: auto; */
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  z-index: inherit;
  outline: none;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
</style>
